<template>
  <div class="app-container">
    <!--    顶部按钮-->
    <el-row
      style="padding-bottom: 15px"
      type="flex"
      align="middle"
      justify="space-between"
    >
      <el-col :span="3">
        <b>我申请的培训 - 已审核</b>
      </el-col>
      <el-col :span="2" :push="3" > 培训名称: </el-col>
      <el-col :span="4" >
        <el-input
          placeholder="请输入"
          v-model="searchTitle"
          clearable
          size="small"
        >
        </el-input>
      </el-col>
<!--      <el-col :span="2" :pull="1"> 审核状态: </el-col>-->
<!--      <el-col :span="2" :pull="2">-->
<!--        <el-input-->
<!--          placeholder="请输入"-->
<!--          v-model="partId"-->
<!--          clearable-->
<!--          type="text"-->
<!--          onkeyup="value=value.replace(/[^\d]/g,'')"-->
<!--          size="small"-->
<!--        >-->
<!--        </el-input>-->
<!--      </el-col>-->
      <el-col :span="6" :pull="2">
        <el-button size="small" icon="el-icon-search" type="primary" @click="handleSearch">搜索</el-button>
        <el-button size="small" @click="clearInput">重置</el-button>
      </el-col>
    </el-row>
    <!--表格-->
    <el-table
      v-loading="loading"
      element-loading-text="加载中"
      stripe
      :data="tableData"
      style="width: 100%"
      :header-cell-style="{ background: '#eee', color: '#333' }"
    >
      <el-table-column prop="createTime" label="申请时间" align="center" width="140"></el-table-column>
      <el-table-column prop="title" label="培训主题" align="center"></el-table-column>
      <el-table-column prop="type" label="培训类型" align="center" ></el-table-column>
      <el-table-column prop="status" label="审核结果" align="center"></el-table-column>
      <!--      <el-table-column  label="审核状态" align="center">{{stausJudge(scope.row.staus)}}</el-table-column>-->
      <el-table-column prop="info" label="审核意见" align="center"></el-table-column>

      <!--      <el-table-column prop="desc" label="培训介绍" align="center"></el-table-column>-->
      <!--      <el-table-column prop="host" label="主持人" align="center"></el-table-column>-->
      <!--      <el-table-column prop="address" label="培训地点" align="center"></el-table-column>-->
      <!--      <el-table-column prop="memo" label="其他要求" align="center"></el-table-column>-->
      <!--      <el-table-column prop="consumer" label="培训对象" align="center"></el-table-column>-->
      <!--      <el-table-column prop="did" label="主办方" align="center"></el-table-column>-->
      <!--      <el-table-column prop="contacter" label="联系人" align="center"></el-table-column>-->
      <!--      <el-table-column prop="phone" label="联系电话" align="center"></el-table-column>-->
      <!--      <el-table-column prop="start_time" label="开始时间" align="center"></el-table-column>-->
      <!--      <el-table-column prop="end_time" label="结束时间" align="center"></el-table-column>-->
      <!--      <el-table-column prop="score" label="分数" align="center"></el-table-column>-->
      <!--      <el-table-column prop="enrollment" label="最大人数" align="center"></el-table-column>-->
<!--      <el-table-column prop="staus" label="审核结果" align="center" width="100"></el-table-column>-->
      <!--      <el-table-column  label="审核状态" align="center">{{stausJudge(scope.row.staus)}}</el-table-column>-->



      <el-table-column label="操作" align="center" width="150" fixed="right">
        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon-more"
            @click="handleDetail(scope.row)">
            详情
          </el-button>

<!--          <el-button-->
<!--            type="info"-->
<!--            size="mini"-->
<!--            icon="el-icon-s-custom"-->
<!--            @click="handleAttend(scope.row)">-->
<!--            报名名单-->
<!--          </el-button>-->
        </template>
      </el-table-column>

    </el-table>
    <!--      分页插件-->
    <el-pagination
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      layout="total, prev, pager, next"
      :page-size="10"
      :total="total"
      style="margin-top: 20px; text-align: right">
    </el-pagination>

    <el-dialog
      :fullscreen="true"
      title="培训详情"
      :visible.sync="detailDialogVisible"
      width="60%"
      class="detailDialog"
    >
      <div>
        <el-row>
          <el-col :span="12">
            <h3>培训ID</h3>
            <span>{{currentData.id}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训主题</h3>
            <span>{{currentData.title}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="24">
            <h3>培训介绍</h3>
            <span>{{currentData.content}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>主讲嘉宾</h3>
            <span>{{currentData.host}}</span>
          </el-col>
          <el-col :span="12">
            <h3>最多可报名人数</h3>
            <span>{{currentData.enrollment}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>主办方</h3>
            <span>{{adminInformation.dname}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训群体</h3>
            <span>{{currentData.consumer}}</span>
          </el-col>
        </el-row>

        <el-divider></el-divider>

        <el-row>
          <el-col :span="12">
            <h3>培训时间</h3>
            <span>{{currentData.startTime}} - </span><span>{{currentData.endTime}}</span>
          </el-col>
          <el-col :span="12">
            <h3>培训地点</h3>
            <span>{{currentData.addressTemp}}</span>

          </el-col>
        </el-row>

        <el-divider></el-divider>

        <h3>培训要求</h3>
        <span>{{currentData.memo}}</span>

      </div>


    </el-dialog>



  </div>
</template>

<script>
import { request } from '@/network/network'
import {train_status_dict, train_consumer_dict,train_type_dict,train_status_options, train_consumer_options } from '@/utils/dict'
import  {formatDate} from '@/utils/date'
  export default {
    data() {
      return {
        loading: true,
        total: 0,
        currentPage: 0,
        searchTitle:"",
        searchMap:"",
        attendDialogVisible: false,
        cancelDialogVisible: false,
        detailDialogVisible: false,
        codeDialogVisible: false,
        currentData:[],
        url: '',
        tableData:[],
        attendPeople:[],
        adminInformation: JSON.parse(sessionStorage.getItem("information"))
      };
    },
    methods: {
      //分页插件绑定事件
      handleCurrentChange(val) {
        this.loading = true
        this.currentPage = val
        this.getTrains(this.searchMap ,val);
      },
      //搜索按钮
      handleSearch(){
        this.searchMap = this.searchTitle
        this.getTrains(this.searchMap);

      },
      //重置搜索框
      clearInput() {
        this.searchTitle = "";
        this.searchMap = "",
          this.getTrains()
      },
      //点击查看详情按钮
      handleDetail(row){
        this.detailDialogVisible=true
        this.currentData = row
        this.detailDialogVisible = true
        this.currentData = row
        let map = JSON.parse(row.mapAddress)
        this.currentData.addressTemp = map.district +' - '+ map.address+' - ' + map.name +' ('+this.currentData.address+')'

      },
      //点击查看参会人员按钮
      handleAttend(row){
        this.attendDialogVisible=true
        this.register(row.id)
      },


      // 取消会议按钮
      handleCancel(index, row) {
        this.$confirm("是否要取消该会议?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.cancelTrain(row.id)
              .then((res) => {
                this.$message({
                  message: "取消成功！",
                  type: "success",
                  duration: 1000,
                });
                this.getTrains();

              })
              .catch((err) => {
                this.$message({
                  message: "发生错误，取消失败！",
                  type: "error",
                  duration: 1000,
                });
                this.getTrains();

              });
          })
          .catch(() => {
            row.purchase = false;
            this.$message({
              message: "操作取消！",
              type: "info",
              duration: 1000,
            });
          });

      },

      //下载按钮
      //TODO 下载二维码功能
      handleDonwload(index, row){
        this.codeDialogVisible = true
      },
      getTrains(title, page){
        return request({
          url: '/train/trains',
          method:'get',
          params:{
            did:this.adminInformation.did,
            title:title,
            page: page,
            status: 1,
            dtype: 0
          }
        }).then(res => {
          this.total = res.data.total
          let temp = res.data.list
          this.tableData = temp.map(item => {
            if(item.info == null || item.info == ''){item.info='-'}
            item.status = train_status_dict[item.status];
            item.consumer =  train_consumer_dict[item.consumer];
            // item.startTime = formatDate(item.startTime)
            // item.endTime = formatDate(item.endTime)
            // item.createTime = formatDate(item.createTime)
            item.type = train_type_dict[item.type]
            return item
          })
          this.loading = false
        })
      },
      cancelTrain(id){
        return request({
          url: '/train/cancelTrain',
          method: 'get',
          params: {
            id: id
          }
        })
      },
      register(tid){
        return request({
          url: '/train/register',
          method: 'get',
          params: {
            tid: tid
          }
        }).then(res => {
          this.attendPeople = res.data
        })
      }

    },
    created() {
      this.getTrains();

    }

  }
</script>

<style scoped>
  .input-width {
    width: 80%;
  }

</style>

