<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">

    <el-tab-pane label="待审核列表" name="first" >
      <Unaudit v-if="isFirst"></Unaudit>
    </el-tab-pane>
    <el-tab-pane label="已审核列表" name="second">
      <Audited v-if="isSecond"></Audited>
    </el-tab-pane>


  </el-tabs>
</template>

<script>

  import Audited from '@/views/trainManageF/myApplyChildrend/Audited'
  import Unaudit from '@/views/trainManageF/myApplyChildrend/Unaudit'
  export default {
    components:{Audited,Unaudit},
    data() {
      return {
        activeName: 'first',
        isFirst: true,
        isSecond: true
      };
    },
    methods: {
      handleClick(tab, event) {
        if(tab.name == "first") {
          this.isFirst = true;
          this.isSecond = false;
        }
        else if(tab.name == "second") {
          this.isFirst = false;
          this.isSecond = true;
        }
      }
    }


  }
</script>

<style scoped>

</style>
